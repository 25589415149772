<template>
  <div class="admin-create-response-team">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">Create Response Team</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <!-- Form -->
    <div class="form">
      <a-form-item label="Team Name">
        <a-input v-model="form.displayName" size="large"></a-input>
      </a-form-item>

      <a-form-item label="Visibility">
        <a-radio-group v-model="form.scope">
          <a-radio :value="1">Shown to organisation</a-radio>
          <!-- <a-radio :value="2">Shown to whole tenant</a-radio> -->
          <a-radio :value="3">Hidden from lists</a-radio>
        </a-radio-group>
      </a-form-item>
    </div>
    <!-- / Form -->

    <a-button
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoadingScreen from "@/components/LoadingScreen";
const _ = require("lodash");
import skills from "@/api/skills";

export default {
  components: { LoadingScreen },

  data() {
    return {
      form: {
        displayName: "",
        scope: 1,
      },

      isSaving: false,
    };
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
  },

  methods: {
    ...mapActions("admin", {
      loadSkillsSilently: "loadSkillsSilently",
    }),

    goBack() {
      this.$router.push("/admin/responders/response-teams");
    },

    getValidationErrors() {
      let errors = [];
      if (this.form.displayName.trim().length == 0) {
        errors.push("Please provide a team name");
      }
      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      let params = {
        ...this.form,
        ownerId: this.selectedOrganisation.id,
      };

      skills
        .addSkill(this.tenantId, params)
        .then((r) => {
          vm.$message.success("Response team created successfully");
          vm.loadSkillsSilently();
          vm.$router.push("/admin/responders/response-teams/" + r.data.id);
        })
        .catch((e) => {
          console.log(e);
          this.$message.error("Error creating response team");
          vm.isSaving = false;
        });
    },

    togglePresenceSelected(presenceId) {
      this.managedGroupLocal.presenceIds = _.xor(
        this.managedGroupLocal.presenceIds,
        [presenceId]
      );
    },
  },

  computed: {
    ...mapGetters("missionControl", {
      presences: "presences",
    }),

    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    myPresenceForOrg() {
      return _.find(this.presences, {
        ownerId: this.selectedOrganisation.id,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.admin-create-response-team {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .top-row {
    display: flex;
    .left {
      flex-shrink: 1;
    }
    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>